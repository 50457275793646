.rating {
    span {
        .fa {
            color: theme-color(primary);
            font-size: 20px;
            margin-right: 2px;
        }
    }
}

.fa-battery-empty {
    color: #d2d2d2;
}